<template>
  <div class="d-flex align-items-start">
    <context-menu ref="context">
      <strong class="mb-2">Serviço</strong>
      <multiselect required searchable close-on-select :show-labels="false" :internal-search="false"
        placeholder="Selecione um Serviço" label="sequence_scope" v-model.trim="serviceScope" :options="contextMenus"
        @input="handleSelectMenu" />
      <hr />
      <strong class="mb-2">Tipo de Documento</strong>
      <b-button v-for="(menu, index) in contextDocuments" :key="index" block
        class="text-left mb-0 px-3 d-flex align-items-center"
        :variant="selectedMenu.id === menu.id ? 'primary' : 'light'" @click="handleSelectDocument(menu)">
        <b-icon class="mr-2" :icon="menu.icon" />
        {{ menu.name }} {{ menu.status === 2 ? '- Encerrado' : '' }}
      </b-button>
    </context-menu>
    <b-card class="w-100 min-h-62 mobile-transparent">
      <router-view />
    </b-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      selectedMenu: {},
      contextMenus: [],
      contextDocuments: [],
      serviceScope: null
    };
  },
  computed: {
    ...mapGetters([
      "user"
    ]),
  },
  methods: {
    ...mapActions(["get_service_provider"]),

    handleSelectMenu(option) {
      this.selectedMenu = {}
      if (option) {
        this.contextDocuments = option.document_types
      } else {
        this.contextDocuments = []
      }
      let path = '/my-enterprise/compliance/certificates/'
      if (!path.includes(this.$route.path)) {
        this.$router.push(path);
      }
    },
    handleSelectDocument(menu) {
      if (this.selectedMenu.id === menu.id) {
        this.$refs.context.close();
        return;
      }

      this.selectedMenu = menu;
      this.$router.push(`/my-enterprise/compliance/certificates/${menu.id}/service/${this.serviceScope.id}`);
    },
    getServiceProvider() {
      this.get_service_provider({
        provider_id: this.user.enterprise_id,
      })
        .then((results) => {
          this.contextMenus = results;
          // if (results.length > 0){
          //   this.handleSelectMenu(results[0])
          // }
        })
    },
  },
  created() {
    this.selectedMenu.url = this.$route.fullPath;
    this.getServiceProvider()
  },
};
</script>
<style lang="css" scoped>
::v-deep .multiselect__content-wrapper {
  position: fixed;
  width: auto;
}
</style>
